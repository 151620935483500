import React, { useState } from "react";
import { Badge, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./type";
import { Link as LK, useLocation, useNavigate } from "react-router-dom";
import { Avatar } from "@nextui-org/react";
import { switchActiveUser, logout } from "../../../store/actions/user";
import CreateTeamModal from "../../Profile/Modals/CreateTeamModal";
import { useResize } from "../../../hooks/useresize.ts";

import "./NavBar.module.css";
import styles from "./NavBar.module.css";

//images
import SvgLogo from "../images/logo.js";
// import SvgLogo from "./images/SvgLogo.js";
import fmava from "../NavBar/images/fmava.jpeg";
import vector from "./images/Vector 912.png";
import { LINKS } from "../../../utils/constants.js";
import { Wallet } from "./Wallet.tsx";

function NavBar() {
  const activeUser = useSelector((state: RootState) => {
    const activeUsers = state.user?.activeUser;
    return Array.isArray(activeUsers)
      ? activeUsers.find((user) => user.id === window.name)?.user
      : null;
  });

  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);
  const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);
  const width = useResize();

  const loc = [
    "/explore",
    "/",
    `/${user?.username}`,
    `/${user?.teams[0]}`,
    "/profile",
    "/explore",
    "/signup",
    "/terms",
    "/privacy",
    "/login",
    "/signup",
  ];

  const handleSwitchActiveUser = (username, projectName) => {
    dispatch(switchActiveUser(username, navigate, projectName));
  };

  const handleMenuAction = (key: string) => {
    switch (key) {
      case "logout":
        dispatch(logout());
        navigate("/");
        break;
      case "profile":
        navigate("/profile");
        break;
      case "finances":
        navigate("/finances");
        break;
      case "create-team":
        openCreateTeamModal();
        break;
      default:
        // dispatch(switchActiveUser(key), navigate);
        handleSwitchActiveUser(key, null);
    }
  };

  const openCreateTeamModal = () => {
    setCreateTeamModalOpen(true);
  };
  const closeCreateTeamModal = () => {
    setCreateTeamModalOpen(false);
  };

  const renderBalance = () => {
    return (
      <>
        {activeUser && (
          <Nav className={styles.balance}>
            <Nav.Link
              as={LK}
              to="/finances"
              className={`${styles.link}`}
            >
              <Wallet wallet={user.wallet} />
            </Nav.Link>
          </Nav>
        )}
      </>
    );
  };

  const menuItems = user
    ? [
        { key: user?.username, name: user?.username, badge: "personal" },
        ...user?.teams?.map((team) => ({
          key: team,
          name: team,
          badge: "team",
        })),
        { key: "create-team", name: "Create Team" },
        { key: "profile", name: "Profile" },
        { key: "finances", name: "Finances" },
        { key: "logout", name: "Logout" },
      ]
    : [];

  const tabs = activeUser
    ? [
        { name: "Explore", path: "/explore", target: "" },
        { name: "My projects", path: `/${activeUser.username}`, target: "" },
        { name: "Pricing", path: `https://flymyai.notion.site/prices?pvs=4`, target: "_blank" },
        // { name: "Enterprise", path: `/` },
        { name: "Contact us", path: LINKS.CONTACT_US, target: "_blank" },
        { name: "Discord", path: LINKS.DISCORD, target: "_blank" },
      ]
    : [
        { name: "Login", path: "/login" },
        { name: "Sign Up", path: "/signup" },
      ];

  return (
    <Navbar
      expand="lg"
      collapseOnSelect
      style={{
        background: loc.includes(location.pathname)
          ? "white"
          : "rgba(238,238,238,0.8)",
      }}
    >
      <Container fluid="lg">
        {activeUser && width < 992 ? (
          <NavDropdown
            className={styles.avatar}
            title={
              <Avatar
                src={fmava}
                alt="avatar"
              />
            }
            id="basic-nav-dropdown"
          >
            {menuItems.map((item, index) => (
              <span key={index}>
                {["create-team", "profile", "logout"].includes(item.key) && (
                  <img
                    src={vector}
                    alt="divider"
                    width={"100%"}
                    height={1}
                    className="pe-0 ps-2"
                  />
                )}
                <NavDropdown.Item
                  key={index}
                  onClick={(_) => handleMenuAction(item.key)}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                    {item.name}
                  </span>
                  <Badge
                    bg="none"
                    className={styles.badge}
                  >
                    {item.badge}
                  </Badge>
                </NavDropdown.Item>
              </span>
            ))}
          </NavDropdown>
        ) : (
          ""
        )}
        <Navbar.Brand
          href="/"
          className={styles.brand}
        >
          {/* <Logo /> */}
          <SvgLogo />
        </Navbar.Brand>
        {width < 992 && renderBalance()}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="custom-toggle ms-4"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto me-0">
            {tabs.map((tab, index) => (
              <Nav.Link
                key={index}
                as={LK}
                to={tab.path}
                target={tab.target}
                eventKey={index}
                className={`${styles.link} ${
                  isActive(tab.path) ? styles.active : ""
                }`}
              >
                {tab.name}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
        {activeUser && width >= 992 ? (
          <>
            {renderBalance()}
            <Nav>
              <NavDropdown
                className={styles.avatar}
                title={
                  <Avatar
                    src={fmava}
                    alt="avatar"
                  />
                }
                id="basic-nav-dropdown"
              >
                {menuItems.map((item, index) => (
                  <span key={index}>
                    {["create-team", "profile", "logout"].includes(
                      item.key
                    ) && (
                      <img
                        src={vector}
                        alt="divider"
                        width={"100%"}
                        height={1}
                        className="pe-0 ps-2"
                      />
                    )}
                    <NavDropdown.Item
                      key={index}
                      onClick={(_) => handleMenuAction(item.key)}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                        {item.name}
                      </span>
                      <Badge
                        bg="none"
                        className={
                          activeUser.username === item.name
                            ? styles.activeBadge
                            : styles.badge
                        }
                      >
                        {item.badge}
                      </Badge>
                    </NavDropdown.Item>
                  </span>
                ))}
              </NavDropdown>
            </Nav>
          </>
        ) : (
          ""
        )}
      </Container>
      <CreateTeamModal
        isOpen={createTeamModalOpen}
        onClose={closeCreateTeamModal}
      />
    </Navbar>
  );
}

export default NavBar;
