import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { HeaderSpacer } from "../../Header/HeaderSpacer";
import { Loading, Spacer } from "@nextui-org/react";
import {
  getProjectInfo,
  getInfStatisticsPerAccelerator,
  getProjectState,
  getCloudAcceleratorList,
} from "../../../api/projects";
import SwaggerClient from "swagger-client";
import { getProjectSchema } from "../../../api/playground";
import styled from "styled-components";
import { getUser } from "../../../api/users";
import Playground from "./InteractivePage/Playground";
import ProjectHeader from "./projectHeader";
import { useSelector } from "react-redux";
import SettingMonitoring from "../SettingMonitoring/SettingMonitoring";
import ApiSectionMain from "./InteractivePage/ApiSectionComponents/ApiSectionMain";
import { Container } from "react-bootstrap";
import HardwareTableModal from "./InteractivePage/HardwareInfo";
import Accordion from "./components/Accordion/index.tsx";
import { toastError } from "../../../utils/toasts.js";

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100% !important;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export default function ProjectMainPage() {
  const { username, projectName } = useParams();
  const [project, setProject] = useState(null);
  const [inputModel, setInputModel] = useState(null);
  const [outputModel, setOutputModel] = useState(null);
  const [availableHardware, setAvailableHardware] = useState([]);
  const [infTimeStatistics, setInfTimeStatistics] = useState([]);
  const [requestUser, setRequestUser] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [isProjectOwner, setIsProjectOwner] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [projectDeploymentInfo, setProjectDeploymentInfo] = useState(null);
  // const requestUserState = useSelector((state) =>
  //   state.user?.activeUser.find((user) => user.id === window.name)
  // )?.user;
  const [requestUserState, setRequestUserState] = useState(null);
  const [development, setDevelopment] = useState(false);
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!Array.isArray(user.activeUser)) {
      setUserLoaded(true);
    } else {
      setRequestUserState(
        user.activeUser.find((user) => user.id === window.name)?.user
      );
      setUserLoaded(false);
    }
  }, [user, user.activeUser]);

  const fetchHardware = async () => {
    return await getCloudAcceleratorList(username, projectName);
  };

  const fetchUser = async () => {
    return await getUser(requestUserState.username);
  };

  const fetchSpec = async () => {
    try {
      const specResponse = await getProjectSchema(
        username,
        projectName,
        apiKey
      );
      return await specResponse.data;
    } catch (error) {
      console.error("Error fetching spec:", error);
      if (error.response.status === 403) {
        toastError(error.response.data.detail);
        navigate("/explore");
      }
    }
  };

  const fetchInfTimeStatistics = async () => {
    const statistics = await getInfStatisticsPerAccelerator(
      username,
      projectName
    );
    setInfTimeStatistics(statistics);
  };

  const parseSpec = (spec) => {
    SwaggerClient.resolve({ spec })
      .then((client) => {
        const models = client.spec.components.schemas;
        setInputModel(models.DynamicInputModel);
        setOutputModel(models.DynamicOutputModel);
      })
      .catch((error) => {
        console.error("Error parsing spec:", error);
      });
  };

  const fetchProjectDeployment = async () => {
    try {
      return await getProjectState(username, projectName);
    } catch (error) {
      console.error("Error fetching project state:", error);
      return null;
    }
  };
  const updateProjectState = async () => {
    const projectDeployment = await fetchProjectDeployment();
    const state = projectDeployment?.state;
    if (projectDeployment) {
      setProject((prevProject) => ({
        ...prevProject,
        state,
      }));
      setProjectDeploymentInfo(projectDeployment);
    }
  };

  useEffect(() => {
    let intervalId;
    if (project) {
      intervalId = setInterval(() => {
        updateProjectState();
      }, 10000);
    }

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [project, username, projectName]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await fetchUser();
        setRequestUser(userData);
        setApiKey(userData.api_key);
        setIsProjectOwner(userData.username === username);
        setUserLoaded(true);
      } catch (error) {
        setUserLoaded(true);
        console.error("Error fetching user data:", error);
      }
    };
    const getProjectData = async () => {
      const projectData = await getProjectInfo(username, projectName);
      setProject(projectData);
    };

    const fetchDeployment = async () => {
      const projectDeployment = await getProjectState(username, projectName);
      setProjectDeploymentInfo(projectDeployment);
    };

    const fetchHardwareData = async () => {
      const hardware = await fetchHardware();
      setAvailableHardware(hardware);
    };
    if (requestUserState) {
      // if (username === requestUserState?.username) {
      fetchUserData();
      getProjectData();
      fetchHardwareData();
      fetchInfTimeStatistics();
      fetchDeployment();
      // } else {
      //   // if (!requestUserState.username)
      //   // dispatch(switchActiveUser(username, navigate, projectName));
      //   setUserLoaded(false);
      // }
    }
    // eslint-disable-next-line
  }, [username, projectName, requestUserState]);

  useEffect(() => {
    if (project && userLoaded && !inputModel && !outputModel) {
      const fetchSpecData = async () => {
        const spec = await fetchSpec();
        parseSpec(spec);
      };
      fetchSpecData();
    }
    // eslint-disable-next-line
  }, [project, userLoaded]);

  if (
    // !userLoaded ||
    !project ||
    !projectDeploymentInfo ||
    !inputModel ||
    !outputModel ||
    !availableHardware ||
    !infTimeStatistics
  ) {
    return (
      <div style={{ height: "100vh" }}>
        <CenteredContent style={{ height: "100%" }}>
          <Loading color={"secondary"} />
        </CenteredContent>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", backgroundColor: "rgba(238,238,238,0.8)" }}>
      <Container fluid="lg">
        <ProjectHeader
          username={username}
          projectInfo={project}
          setProjectInfo={setProject}
          availableHardware={availableHardware}
          infTimeStatistics={infTimeStatistics}
          requestUser={requestUser}
          isProjectOwner={isProjectOwner}
        />
        <Spacer y={1.5} />
        <Playground
          username={username}
          projectName={project.name}
          projectInfo={project}
          availableHardware={availableHardware}
          description={project.description}
          apiKey={apiKey}
          inputModel={inputModel}
          outputModel={outputModel}
          isProjectOwner={isProjectOwner}
          requestUser={requestUser}
          setRequestUser={setRequestUser}
        />
        <Spacer y={1.5} />
        {isProjectOwner && (
          <>
            <SettingMonitoring
              username={username}
              projectInfo={project}
              setProjectInfo={setProject}
              availableHardware={availableHardware}
              projectDeploymentInfo={projectDeploymentInfo}
              updateProjectState={updateProjectState}
            />
            <Spacer y={1.5} />
          </>
        )}

        <ApiSectionMain
          username={username}
          projectInfo={project}
          inputModel={inputModel}
          outputModel={outputModel}
          apikey={apiKey}
        />
        {/*<div style={{"width": "100%", backgroundColor: "rgba(238,238,238,0.8)"}}>*/}
        {/*    <InteractivePage*/}
        {/*        username={username}*/}
        {/*        projectInfo={project}*/}
        {/*        setProjectInfo={setProject}*/}
        {/*        availableHardware={availableHardware}*/}
        {/*        apiKey={apiKey}*/}
        {/*        inputModel={inputModel}*/}
        {/*        outputModel={outputModel}*/}
        {/*        isProjectOwner={isProjectOwner}*/}
        {/*        requestUser={requestUser}*/}
        {/*        projectDeploymentInfo={projectDeploymentInfo}*/}

        {/*    />*/}
        {/*   */}
        {/*</div>*/}

        {requestUser && requestUser.group === 1 && (
          <Container
            style={{
              backgroundColor: "white",
              marginTop: "16px",
              padding: "0px 10px",
            }}
            fluid
          >
            <Accordion
              state={development}
              func={() => setDevelopment(!development)}
              title="Development"
            />
            {development && (
              <HardwareTableModal
                username={username}
                projectName={projectName}
              />
            )}
          </Container>
        )}
      </Container>
      <HeaderSpacer />
    </div>
  );
}
