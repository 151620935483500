import React from "react";

import styles from "./NavBar.module.css";
import SvgIconPlus from "./images/SvgIconPlus";

export const Wallet: React.FC<Balance> = ({ wallet }) => {
  return (
    <div className={styles.wallet}>
      <span>
        {/* <div className={styles.balanceText}>Balance</div> */}
        <div className={styles.balanceValue}>${parseFloat(wallet).toFixed(2)}</div>
      </span>
      <span>
        <SvgIconPlus />
      </span>
    </div>
  );
};


interface Balance {
  wallet: string
}