import { Image } from "react-bootstrap";

import styles from "./Tags.module.css";

import commercial from "./images/commercial.svg";
import research from "./images/research.svg";
import asselerated from "./images/asselerated.svg";
import publicI from "./images/public.svg";
import privateI from "./images/private.svg";
import userI from "./images/user.svg";

export default function Tags({props}) {
    const {has_commercial_license, is_public, is_optimized, is_partner_api} = props;
    return (
        <div className={styles.badgeContainer}>
            {is_optimized &&
                <Badge
                    text="Accelerated by FMA"
                    style={{
                        background: "linear-gradient(#1A67C1, #0F32AF)",
                        color: "white"
                    }}
                    image={asselerated}/>}
            {is_public ?
                <Badge
                    text="Public"
                    style={{
                        background: "#DAFBE1",
                        color: "#3F3D53",
                    }}
                    image={publicI}/>
                :
                <Badge
                    text="Private"
                    style={{
                        background: "#FFEFE7",
                        color: "#3F3D53",
                    }}
                    image={privateI}/>
            }
            {has_commercial_license ?
                <Badge
                    text="For commercial use"
                    style={{background: "#EBF7BA"}}
                    image={commercial}/>
                :
                <Badge
                    text="For research only"
                    style={{background: "#C5E0FE"}}
                    image={research}/>
            }
            {
                is_partner_api ?
                    <Badge text="Partner API"
                           style={{background: "#c8ede0"}}
                           image={userI}
                    />
                    :
                    null
            }

            {/* {predictions_count &&
                <Badge
                    text={`Runs ${predictions_count}`}
                    style={{ background: "#FFEFE7" }}
                    image={runs}
                />} */}
        </div>
    );
}

function Badge({ text, style, image }) {
    return (
        <div className={styles.main} style={{ ...style }}>
            <Image src={image} alt="image" />
            {text}
        </div>
    );
}