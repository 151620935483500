import React from "react";
import { getProjects } from "../../api/projects";
import { useSelector } from "react-redux";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { HeaderSpacer } from "../Header/HeaderSpacer";
import { renderProjects } from "../../scripts/renderListAI";
import { CenteredContent } from "./ProjectMain/ProjectMainPage";
import useProjectPagination from "../../hooks/useProjectPagination.ts";
import { TITLE_SPLIT_GROUP } from "../../utils/constants.js";
import { Link } from "react-router-dom";

function ProjectList() {
  const size = { xs: 12, sm: 6, md: 6, lg: 4, xl: 4 };
  const activeUser = useSelector((state) =>
    state.user?.activeUser.find((user) => user.id === window.name)
  );

  const username = activeUser ? activeUser.user.username : null;

  const { activeProjects, notActiveProjects, error, loading, lastProjectRef } =
    useProjectPagination(getProjects, username);

  if (error) {
    return <div className="error">{error}</div>;
  }
  return (
    <Container
      fluid="lg"
      style={{ minHeight: "calc(100vh - 190px)", marginTop: "0px" }}
    >
      <HeaderSpacer />
      <Row>
        <Col
          xs={3}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {/* <Button bordered color={'secondary'} auto onClick={() => setIsModalOpen(true)}>Create new project</Button> */}
        </Col>
      </Row>
      {!activeProjects.length && !notActiveProjects.length && !loading && (
        <Row>
          <Col style={{ textAlign: "center", padding: "16vh 16px" }}>
            <h6>
              There is no your private models yet. Just clone any model from
              &nbsp;
              <Link
                to="/explore"
                style={{ color: "#1053d4" }}
              >
                Public
              </Link>
              &nbsp; or &nbsp;
              <Link
                to="https://tally.so/r/3NVg5N"
                style={{ color: "#1053d4" }}
              >
                Contact us
              </Link>{" "}
              &nbsp;to add new model for you.
            </h6>
          </Col>
        </Row>
      )}
      <HeaderSpacer />
      {renderProjects(
        activeProjects,
        TITLE_SPLIT_GROUP.ACTIVE,
        lastProjectRef,
        size
      )}
      {renderProjects(
        notActiveProjects,
        TITLE_SPLIT_GROUP.NOT_ACTIVE,
        lastProjectRef,
        size
      )}
      {loading && (
        <CenteredContent>
          <Spinner
            animation="border"
            role="status"
            variant="secondary"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </CenteredContent>
      )}
    </Container>
  );
}

export default ProjectList;
