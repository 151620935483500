import { useState, useEffect, useRef, useCallback } from "react";
import { ACTIVE, NOT_ACTIVE } from "../utils/constants";

/**
 * Custom hook for paginating projects based on their state.
 *
 * @param {FetchDataFunction} func - The function to fetch project.
 * @param {string} [username] - Optional username to filter projects by owner.
 * @returns {Object} An object containing:
 *   - activeProjects: Array of active projects.
 *   - notActiveProjects: Array of not active projects.
 *   - error: Error message if any error occurs during data fetching.
 *   - loading: Boolean indicating if data is currently being loaded.
 *   - lastProjectRef: Ref callback to be attached to the last project element for infinite scrolling.
 */

const useProjectPagination = (
  func: FetchDataFunction,
  username?: string
): object => {
  const [activeProjects, setActiveProjects] = useState<Project[]>([]);
  const [notActiveProjects, setNotActiveProjects] = useState<Project[]>([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [notActivePage, setNotActivePage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreNot, setHasMoreNot] = useState(true);

  const loadingRef = useRef(false);
  const lastUserName = useRef<string | undefined>("");
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (lastUserName.current !== username) {
      setActiveProjects([]);
      setNotActiveProjects([]);
      setError(null);
      setLoading(false);
      setPage(1);
      setPage(1);
      setHasMore(true);
      setHasMoreNot(true);
      lastUserName.current = username;
    }
  }, [username]);

  const fetchData = useCallback(
    async (page: number, username?: string) => {
      if (loadingRef.current) return;

      setLoading(true);
      loadingRef.current = true;
      let data: FetchDataResult = defaultFetchDataResult;

      try {
        if (hasMore) {
          data = await func(
            ...(username ? [username, page, ACTIVE] : [page, ACTIVE])
          );
          setActiveProjects((prev) => [...prev, ...data.results]);
          setHasMore(Boolean(data.next));
        }
        if (!data || !data?.next) {
          data = await func(
            ...(username
              ? [username, notActivePage, NOT_ACTIVE]
              : [notActivePage, NOT_ACTIVE])
          );
          setNotActiveProjects((prev) => [...prev, ...data.results]);
          setHasMoreNot(Boolean(data.next));
        }
      } catch (error) {
        setError(error.detail);
        console.log("r", error.detail);
      } finally {
        setLoading(false);
        loadingRef.current = false;
      }
    },
    [func, hasMore, notActivePage]
  );

  useEffect(() => {
    if (page !== 1) {
      fetchData(page, username);
    } else {
      fetchData(1, username);
    }
  }, [page, username, fetchData]);

  useEffect(() => {
    if (notActivePage !== 1) {
      fetchData(notActivePage, username);
    }
  }, [notActivePage, username, fetchData]);

  const loadMoreProjects = useCallback(() => {
    if (hasMore && !loadingRef.current) {
      setPage((prevPage) => prevPage + 1);
    }
    if (hasMoreNot && !loadingRef.current && !hasMore) {
      setNotActivePage((prevPage) => prevPage + 1);
    }
  }, [hasMore, hasMoreNot]);

  const lastProjectRef = useCallback((node: HTMLElement | null) => {
    if (observer.current) observer.current.disconnect();
    if (node && observer.current) observer.current.observe(node);
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreProjects();
      }
    }, options);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loadMoreProjects]);

  return {
    activeProjects,
    notActiveProjects,
    error,
    loading,
    lastProjectRef,
  };
};

export default useProjectPagination;

interface FetchDataResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: Project[];
}

type FetchDataFunction = (...args: any) => Promise<FetchDataResult>;

const defaultFetchDataResult: FetchDataResult = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};
