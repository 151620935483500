import React from "react";
import { Col, Row } from "react-bootstrap";

import styles from "./index.module.css";

export default function MenuItem({
  item,
  count,
}: {
  item: string;
  count: number;
}) {
  return (
    <Row>
      <Col style={{ display: "flex" }}>
        <span>
          <input
            className={styles.checkBox} 
            type="checkbox"
            id={item + count}
            style={{color: "white"}}
          />
        </span>
        <label
          htmlFor={item + count}
          className={styles.item}
        >
          {item}
        </label>
      </Col>
      {/* <Col md={1} className={styles.count}>{count}</Col> */}
    </Row>
  );
}
