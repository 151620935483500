import React from "react";

const SvgIconPlus = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="40"
      height="40"
      fill="#0A0C19"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.25 19.75V15H20.75V19.75H25.5V21.25H20.75V26H19.25V21.25H14.5V19.75H19.25Z"
      fill="white"
    />
  </svg>
);

export default SvgIconPlus;
