const initialState = {
  user: null,
  activeUser: [],
  teams: [],
  error: null,
  isLoading: false,
  lastUser: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return { ...state, isLoading: true, error: null };
    case "SET_LAST_ACTIVE_USER":
      return { ...state, lastUser: action.payload };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload,
        activeUser: [
          ...state.activeUser,
          { id: window.name, user: action.payload },
        ],
        teams: action.payload.teams || [],
        isLoading: false,
        error: null,
      };
    case "LOGIN_FAILURE":
      return { ...state, error: action.payload, isLoading: false };
    case "DELETE_ACTIVE_USER": {
      const newActiveUsers = state.activeUser.filter(
        (user) => user.id !== action.payload
      );
      return { ...state, activeUser: newActiveUsers };
    }
    case "USER_LOGOUT":
      return { ...initialState };
    case "SET_ACTIVE_USER": {
      if (!Array.isArray(state.activeUser)) {
        console.error(
          "Expected activeUser to be an array, got:",
          state.activeUser
        );
        return {
          ...state,
          activeUser: [{ id: window.name, user: action.payload }],
        };
      }

      const indexUser = state.activeUser.findIndex(
        (user) => user.id === window.name
      );

      if (indexUser !== -1) {
        const updateActiveUser = [...state.activeUser];
        updateActiveUser[indexUser] = {
          ...updateActiveUser[indexUser],
          user: action.payload,
        };
        return {
          ...state,
          activeUser: updateActiveUser,
        };
      } else {
        return {
          ...state,
          activeUser: [
            ...state.activeUser,
            { id: window.name, user: action.payload },
          ],
        };
      }
    }
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default user;
